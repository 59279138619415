<template>
  <el-container class="settings-page">
    <el-header class="settings-header">
      <div class="settings-title">
        <h3 v-text="$t('Settings')" />
      </div>
    </el-header>

    <el-row :gutter="20" class="settings-container">
      <el-col :xs="24" :sm="24" :md="12" :lg="12" class="card-column">
        <VCard>
          <div class="card-content">
            <el-form
              ref="reminderForm"
              label-position="left"
              label-width="150px"
              v-loading="$wait.is($waiters.License.Settings)"
              class="settings-form"
            >
              <el-form-item label="Reminder days" :error="reminderDayError">
                <div class="input-wrapper">
                  <div class="input-row">
                    <el-input
                      v-model.number="newReminderDay"
                      placeholder="e.g. 7"
                      class="flex-input"
                      @input="validateReminderDay"
                    />
                    <el-button
                      type="primary"
                      :disabled="!!reminderDayError || !newReminderDay"
                      @click="addReminderDay"
                      class="form-button"
                    >
                      Add
                    </el-button>
                  </div>
                  <div class="form-description">
                    Set how many days before the license expires a reminder email should be sent. Multiple values can be
                    added.
                  </div>
                </div>
              </el-form-item>

              <ul class="reminder-days-list" v-if="reminderDays.length">
                <li v-for="day in reminderDays" :key="day" class="reminder-day-item">
                  A reminder email will be sent to the license contact {{ day }} days before the license expires.
                  <el-tooltip
                    effect="dark"
                    content="Remove this reminder. No reminder emails will be sent this many days before expiration."
                    placement="top"
                  >
                    <el-button
                      type="text"
                      class="delete-button"
                      icon="el-icon-delete"
                      @click="removeReminderDay(day)"
                    />
                  </el-tooltip>
                </li>
              </ul>
            </el-form>
          </div>
        </VCard>
      </el-col>

      <el-col :xs="24" :sm="24" :md="12" :lg="12" class="card-column">
        <VCard>
          <div class="card-content">
            <el-form
              ref="renewalForm"
              label-position="left"
              label-width="150px"
              v-loading="$wait.is($waiters.License.Settings)"
              class="settings-form"
            >
              <el-form-item :label="$t('Renewal Period (days)')" :error="renewalPeriodError">
                <div class="input-wrapper">
                  <div class="input-row">
                    <el-input
                      v-model.number="renewalPeriod"
                      placeholder="e.g. 30"
                      class="flex-input"
                      @input="validateRenewalPeriod"
                    />
                    <el-button
                      type="primary"
                      :disabled="!!renewalPeriodError"
                      @click="saveRenewalPeriod"
                      class="form-button"
                    >
                      Save
                    </el-button>
                  </div>
                  <div class="form-description">
                    Set how many days after license expiration it can still be renewed.
                  </div>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </VCard>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>
import { SettingsService } from '@/api/Services/License/SettingsService'
import { isEmpty } from 'class-validator'

export default {
  name: 'Settings',

  data() {
    return {
      renewalPeriod: undefined,
      reminderDays: [],
      newReminderDay: undefined,
      renewalPeriodError: '',
      reminderDayError: '',
    }
  },

  methods: {
    fetchSettings() {
      this.$request(async () => {
        const { data } = await SettingsService.get()
        if (data.success) {
          this.renewalPeriod = data.result.renewalPeriod
          this.reminderDays = data.result.reminderDays
        }
      }, this.$waiters.License.Settings)
    },

    async saveRenewalPeriod() {
      if (this.validateRenewalPeriod()) {
        this.$request(async () => {
          const { data } = await SettingsService.updateRenewalPeriod({ renewalPeriod: this.renewalPeriod })
          if (data.success) {
            this.$message.success(this.$t('Renewal period updated successfully'))
          }
        }, this.$waiters.License.Settings)
      }
    },

    async updateReminderDays() {
      this.$request(async () => {
        const { data } = await SettingsService.updateReminderDays({ reminderDays: this.reminderDays })
        if (data.success) {
          this.$message.success(this.$t('Reminder days updated successfully'))
        }
      }, this.$waiters.License.Settings)
    },

    addReminderDay() {
      if (this.validateReminderDay()) {
        this.reminderDays.push(this.newReminderDay)
        this.reminderDays.sort((a, b) => a - b)
        this.updateReminderDays()
        this.newReminderDay = undefined
      }
    },

    removeReminderDay(day) {
      this.reminderDays = this.reminderDays.filter((d) => d !== day)
      this.updateReminderDays()
    },

    validateRenewalPeriod() {
      if (isEmpty(this.renewalPeriod)) {
        this.renewalPeriodError = 'Renewal period cannot be empty.'
        return false
      }
      if (this.renewalPeriod <= 0) {
        this.renewalPeriodError = 'Renewal period must be greater than 0.'
        return false
      }
      this.renewalPeriodError = ''
      return true
    },

    validateReminderDay() {
      if (isEmpty(this.newReminderDay)) {
        this.reminderDayError = 'Reminder days cannot be empty.'
        return false
      }
      if (this.newReminderDay <= 0) {
        this.reminderDayError = 'Reminder days must be greater than 0.'
        return false
      }
      if (this.reminderDays.includes(this.newReminderDay)) {
        this.reminderDayError = 'This reminder day is already added.'
        return false
      }
      this.reminderDayError = ''
      return true
    },
  },

  created() {
    this.fetchSettings()
  },
}
</script>

<style lang="scss" scoped>
.settings-header {
  height: auto !important;
  padding: 0 2px !important;
  margin: 0 0 10px 0 !important;
  display: flex !important;
  align-items: baseline;
}

.settings-title {
  flex: 1;
}

.settings-container {
  display: flex;
  flex-wrap: wrap;
}

.card-column {
  margin-bottom: 20px;
}

.card-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.settings-form {
  flex: 1;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.input-row {
  display: flex;
  align-items: center;
  width: 100%;
}

.flex-input {
  flex: 1;
}

.form-button {
  margin-left: 20px;
  white-space: nowrap;
}

.form-description {
  font-size: 12px;
  color: #999;
  line-height: 1.5;
  word-wrap: break-word;
  white-space: normal;
}

.reminder-days-list {
  list-style: none;
  padding: 0;
  margin: 15px 0 0;
}

.reminder-day-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  background: #f5f7fa;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.5;

  .delete-button {
    margin-left: auto;
    color: red;
  }
}

@media (max-width: 400px) {
  .input-row {
    flex-direction: column;
    align-items: stretch;
  }

  .form-button {
    margin-left: 0;
    margin-top: 10px;
    align-self: flex-end;
  }
}
</style>
